import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
<path d="M5790 7624 c-30 -8 -74 -25 -99 -37 -62 -30 -152 -121 -187 -187
l-29 -55 -3 -688 -3 -687 388 2 388 3 3 479 2 478 288 -5 c337 -5 407 -17 561
-93 168 -83 288 -233 336 -419 20 -78 37 -258 33 -360 l-3 -80 393 -3 392 -2
0 137 c0 255 -27 437 -92 623 -66 186 -158 331 -302 476 -219 217 -521 352
-926 410 -167 24 -1059 31 -1140 8z"/>
<path d="M3540 7609 c-327 -25 -591 -135 -740 -309 -54 -62 -118 -165 -147
-237 -29 -70 -66 -221 -60 -244 3 -10 0 -20 -7 -22 -8 -3 -11 -102 -11 -363 0
-302 3 -375 18 -459 29 -160 75 -287 144 -394 27 -43 37 -51 45 -40 8 11 9 11
4 -3 -10 -33 121 -147 232 -202 70 -35 157 -62 265 -80 69 -12 180 -16 525
-16 457 -1 518 -6 579 -45 37 -24 50 -41 75 -94 17 -38 21 -66 22 -190 1 -166
-11 -215 -67 -269 -70 -68 -45 -66 -837 -72 -651 -5 -719 -7 -760 -23 -175
-69 -243 -183 -244 -407 -1 -174 20 -243 103 -335 40 -46 113 -84 188 -100 99
-21 1489 -20 1638 1 55 8 107 17 115 20 8 4 22 8 30 9 61 8 91 18 215 73 207
92 353 250 435 472 62 169 65 198 65 625 0 412 -4 463 -54 635 -68 240 -239
432 -456 513 -157 59 -161 59 -700 67 -275 4 -513 11 -530 15 -41 11 -98 48
-124 81 -34 43 -43 98 -39 234 5 179 34 227 157 264 48 14 137 16 710 16 392
0 681 4 718 10 122 20 206 72 260 162 51 83 66 162 61 304 -3 66 -9 127 -14
135 -4 8 -10 23 -11 33 -4 26 -60 115 -86 138 -40 34 -106 70 -159 86 -46 14
-141 16 -753 18 -385 1 -747 -2 -805 -7z"/>
<path d="M5725 5850 c3 -5 0 -7 -8 -4 -40 15 -55 -87 -17 -118 19 -15 42 -18
145 -18 156 0 175 -6 175 -59 0 -22 -6 -43 -12 -48 -7 -4 -63 -10 -125 -13
-125 -6 -150 -17 -188 -81 -17 -29 -20 -52 -20 -167 l0 -134 28 -24 c28 -24
31 -24 231 -24 231 0 236 2 236 77 0 64 -20 72 -197 75 l-148 3 1 44 c3 66 16
74 143 80 89 5 115 10 138 27 47 35 63 78 63 177 0 125 -28 179 -109 206 -43
14 -345 15 -336 1z"/>
<path d="M6689 5853 c-1 -5 -1 -11 0 -15 0 -4 -5 -5 -13 -2 -21 8 -26 -28 -26
-188 l0 -148 -74 0 c-111 0 -118 8 -121 144 -3 120 -9 133 -66 142 -25 4 -41
1 -59 -14 -24 -19 -24 -23 -25 -168 0 -138 2 -152 23 -185 35 -57 71 -69 206
-69 l116 0 0 -73 c0 -60 4 -77 20 -95 25 -26 75 -29 108 -6 22 15 22 16 22
328 0 300 -1 313 -20 334 -20 21 -88 32 -91 15z"/>
<path d="M7295 5848 c-38 -22 -90 -109 -81 -134 4 -10 2 -12 -6 -8 -8 5 -9 2
-5 -10 4 -9 3 -15 -2 -12 -12 8 -101 -191 -95 -211 4 -12 3 -14 -3 -6 -6 8
-28 -28 -67 -110 -77 -160 -82 -177 -65 -188 26 -16 85 -10 113 12 15 12 43
56 63 98 19 42 40 84 45 95 50 96 208 442 208 456 0 30 -65 41 -105 18z m-220
-658 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z"/>
<path d="M7580 5840 c-26 -26 -26 -80 -1 -111 18 -22 28 -24 143 -29 l125 -5
-114 -235 c-124 -257 -132 -287 -79 -301 43 -12 105 5 137 38 15 15 60 98 99
183 40 85 85 182 100 216 55 118 66 208 30 244 -19 19 -33 20 -220 20 -187 0
-201 -1 -220 -20z"/>
<path d="M5467 4533 c3 -542 4 -554 45 -636 46 -92 161 -182 271 -212 56 -15
113 -16 562 -12 524 4 576 8 780 57 533 126 892 439 1044 910 30 94 68 284 73
370 l3 45 -391 3 -390 2 -22 -102 c-61 -288 -232 -471 -510 -545 -92 -25 -113
-27 -389 -31 l-293 -4 0 341 0 341 -392 0 -393 0 2 -527z"/>
<path d="M1565 3401 c-83 -38 -127 -110 -105 -176 16 -48 63 -89 166 -144 104
-56 130 -87 100 -120 -41 -45 -106 -13 -106 51 0 31 4 29 -103 30 -32 0 -61
-2 -64 -6 -16 -15 -3 -79 22 -116 59 -86 225 -112 341 -54 49 24 63 37 83 77
46 92 2 164 -149 242 -116 59 -142 97 -88 126 31 16 55 2 65 -39 l9 -37 76 -3
c67 -2 78 0 87 16 27 50 -37 136 -118 161 -59 17 -170 14 -216 -8z"/>
<path d="M2100 3388 c-11 -18 -20 -38 -20 -44 0 -5 -32 -115 -70 -244 -39
-129 -68 -240 -65 -248 3 -9 26 -12 87 -10 l83 3 11 35 c12 35 12 35 69 35 57
0 57 0 69 -35 l11 -35 79 -3 c44 -2 83 1 89 7 6 6 0 41 -18 98 -14 48 -40 133
-57 188 -69 232 -80 261 -95 273 -10 7 -46 12 -85 12 -66 0 -69 -1 -88 -32z
m122 -330 c3 -29 0 -33 -24 -35 -35 -2 -41 8 -29 54 5 21 12 52 16 68 l8 30
13 -42 c7 -24 14 -57 16 -75z"/>
<path d="M2510 3410 c-13 -8 -15 -52 -18 -276 -2 -192 0 -270 9 -280 13 -16
154 -20 164 -4 3 5 6 59 6 120 0 60 3 110 8 110 4 0 33 -54 64 -120 l56 -120
75 0 c47 0 78 4 82 12 4 6 7 133 7 281 0 220 -3 271 -14 278 -24 15 -135 11
-149 -6 -8 -10 -12 -52 -12 -125 0 -60 -3 -110 -7 -110 -4 0 -34 55 -66 123
l-60 122 -65 3 c-36 1 -72 -2 -80 -8z"/>
<path d="M3143 3395 c-32 -16 -61 -41 -79 -67 -29 -41 -29 -42 -29 -197 0
-174 7 -199 67 -244 53 -38 98 -52 173 -50 118 1 200 53 225 141 12 46 13 243
0 296 -13 57 -39 87 -100 119 -75 38 -183 39 -257 2z m167 -95 c18 -18 20 -33
20 -168 0 -165 -8 -192 -59 -192 -48 0 -51 11 -51 192 0 148 2 168 18 177 26
16 50 13 72 -9z"/>
<path d="M4507 3407 c-19 -6 -29 -19 -37 -45 -5 -20 -41 -140 -79 -267 -48
-161 -66 -234 -59 -242 14 -17 142 -17 156 0 6 6 13 24 17 40 7 26 10 27 71
27 55 0 64 -3 64 -18 0 -10 7 -28 16 -40 13 -19 24 -22 85 -22 53 0 71 4 76
15 5 15 -11 84 -42 180 -7 22 -34 110 -60 195 -26 85 -52 160 -59 166 -15 15
-115 23 -149 11z m102 -349 c5 -15 6 -29 3 -32 -7 -7 -67 -8 -74 -1 -4 5 17
111 29 145 4 9 12 -6 19 -35 8 -27 18 -62 23 -77z"/>
<path d="M6045 3401 c-81 -37 -123 -107 -104 -174 13 -47 64 -92 166 -147 103
-55 129 -86 99 -119 -41 -45 -106 -13 -106 51 0 20 -5 28 -17 29 -49 3 -145 1
-150 -4 -15 -16 -3 -81 22 -117 71 -103 300 -114 401 -19 12 11 27 40 34 64
24 86 -25 152 -162 221 -40 20 -81 43 -90 52 -24 21 -23 59 2 72 33 17 57 3
67 -38 l9 -37 76 -3 c67 -2 78 0 87 16 26 49 -29 129 -108 157 -61 21 -176 19
-226 -4z"/>
<path d="M6468 3409 c-16 -9 -18 -33 -18 -278 0 -216 3 -271 14 -280 9 -8 75
-11 217 -9 l204 3 0 45 0 45 -125 5 -125 5 -3 58 -3 57 95 0 c120 0 126 2 126
51 0 51 -17 59 -129 59 l-91 0 0 70 0 70 109 0 c60 0 116 3 125 6 19 7 22 74
4 92 -15 15 -375 16 -400 1z"/>
<path d="M6972 3407 c-9 -11 -12 -83 -10 -288 l3 -274 73 -3 c46 -2 79 2 88 9
10 8 14 36 14 100 0 65 3 89 13 89 6 0 38 -45 70 -100 l57 -100 85 0 c68 0 85
3 85 15 0 8 -27 58 -59 112 l-60 98 34 28 c44 36 70 103 61 159 -10 62 -43
105 -106 136 -50 25 -67 27 -195 30 -115 3 -143 1 -153 -11z m260 -114 c13
-12 18 -30 18 -69 0 -61 -21 -84 -77 -84 l-33 0 0 85 0 85 37 0 c20 0 45 -7
55 -17z"/>
<path d="M7476 3403 c-7 -17 139 -526 157 -550 6 -9 38 -13 92 -13 l83 0 16
43 c9 23 47 147 86 276 51 174 66 238 58 248 -7 8 -35 13 -79 13 -87 0 -88 -2
-129 -185 -17 -77 -32 -144 -34 -150 -4 -13 -17 39 -47 185 -15 70 -31 122
-41 132 -25 25 -153 26 -162 1z"/>
<path d="M8046 3404 c-14 -13 -16 -52 -16 -277 0 -144 4 -268 9 -275 5 -9 30
-12 87 -10 l79 3 3 274 c2 193 -1 278 -9 287 -17 20 -133 19 -153 -2z"/>
<path d="M8415 3410 c-43 -13 -105 -68 -121 -106 -10 -23 -14 -77 -14 -176 0
-175 13 -212 92 -257 153 -87 378 -10 378 130 0 50 -14 59 -91 59 -76 0 -86
-7 -91 -65 -2 -33 -8 -41 -30 -49 -20 -7 -35 -6 -53 4 l-25 13 0 163 0 164 26
17 c24 15 29 15 53 2 15 -9 27 -25 29 -39 8 -74 5 -71 95 -68 l82 3 0 50 c-1
101 -79 158 -220 162 -44 1 -93 -2 -110 -7z"/>
<path d="M8830 3410 c-22 -14 -28 -541 -7 -559 10 -8 73 -11 218 -9 l203 3 6
25 c4 14 4 34 0 45 -6 18 -19 20 -131 25 l-124 5 -3 58 -3 57 105 0 c60 0 107
4 111 10 4 6 7 24 7 42 2 48 -21 58 -129 58 l-94 0 3 68 3 67 115 3 c123 3
140 10 140 58 0 50 -17 54 -218 54 -103 0 -194 -5 -202 -10z"/>
<path d="M3827 3403 c-4 -3 -7 -129 -7 -279 l0 -274 25 -6 c13 -3 84 -4 157
-2 143 4 186 17 235 70 36 39 43 73 43 219 0 151 -5 172 -57 221 -51 49 -89
58 -246 58 -79 0 -147 -3 -150 -7z m240 -94 c15 -5 31 -16 35 -22 4 -7 8 -80
8 -163 0 -178 -3 -187 -73 -182 -26 2 -47 6 -47 11 0 4 0 88 0 187 l0 180 24
0 c14 0 37 -5 53 -11z"/>
<path d="M4766 3394 c-9 -24 -7 -62 4 -69 5 -3 37 -7 70 -7 l60 -1 0 -226 c0
-163 3 -230 12 -239 16 -16 144 -16 155 1 4 6 8 113 8 237 l0 225 45 1 c81 2
89 4 95 25 4 11 4 31 1 45 l-6 24 -219 0 c-190 0 -220 -2 -225 -16z"/>
<path d="M5311 3393 c-20 -38 -82 -264 -75 -275 4 -6 3 -8 -2 -5 -12 7 -89
-247 -79 -262 9 -15 138 -14 157 2 9 6 18 23 20 37 3 24 6 25 67 25 63 0 65
-1 72 -29 11 -40 24 -46 102 -46 49 0 69 4 73 14 3 8 -16 83 -41 168 -26 84
-60 198 -76 252 -44 144 -36 136 -129 136 -65 0 -81 -3 -89 -17z m22 -8 c-3
-9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m86 -262 c7 -32 15 -68 18
-80 5 -21 1 -23 -30 -23 -20 0 -38 3 -40 8 -7 10 25 152 33 152 4 0 12 -26 19
-57z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
